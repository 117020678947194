









import Vue from 'vue'

import Date from '@/components/Card/History/Date.vue'
import SuspectedLogs from '@/components/Card/History/SuspectedLogs.vue'
import SuspectedHistory from '@/components/Card/History/Suspected.vue'
import SelectThing from '@/components/Select/Thing.vue'

export default Vue.extend({
  components: {
    Date,
    SuspectedLogs,
    SuspectedHistory,
    SelectThing
  }
})
