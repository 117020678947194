



















import Vue from 'vue'
import { mapState } from 'vuex'

import { IStateHistory } from '@/store/modules/history'

export default Vue.extend({
  computed: {
    ...mapState<IStateHistory>('history', {
      getSuspectedMotionLogs: (state: IStateHistory) => state.suspectedMotionHistory
    })
  }
})
