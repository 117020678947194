var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "flex" },
    [
      _c("SelectThing"),
      _c("Date"),
      _c("SuspectedHistory"),
      _c("SuspectedLogs")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }