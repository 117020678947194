







import { Vue, Component, Watch } from 'vue-property-decorator'
import { mapState, mapGetters } from 'vuex'
import { Chart } from 'highcharts-vue'

import { IStateHistory, IMotionEventRange, IRecentHistoryEventGraph } from '@/store/modules/history'
import { IStateThing } from '@/store/modules/thing'
import chartOptions from '@/utils/charts/motionChart'
import { chart } from 'highcharts'

@Component({
  components: {
    Chart
  },
  computed: {
    ...mapState<IStateThing>('thing', {
      serial: (state: IStateThing) => state.serial
    }),
    ...mapState<IStateHistory>('history', {
      hasMotionHistory: (state: IStateHistory) =>
        state.suspectedMotionHistory && state.suspectedMotionHistory.length > 0
    }),
    ...mapGetters('history', ['suspectedMotionEvents', 'suspectedMotionRanges'])
  }
})
export default class ChartComponent extends Vue {
  private chartOptions: any = chartOptions
  private hasMotionHistory!: boolean
  private suspectedMotionEvents!: IRecentHistoryEventGraph[]
  private suspectedMotionRanges!: IMotionEventRange[]

  @Watch('serial')
  cleanData() {
    const { chartMotionReference } = this.$refs
    if (!chartMotionReference) return

    const chartSerie = (chartMotionReference as any).chart.series[0]
    chartSerie.setData([])
  }

  @Watch('serial')
  setData() {
    const { chartMotionReference } = this.$refs
    if (!this.suspectedMotionEvents || !chartMotionReference) return

    const chart = (chartMotionReference as any).chart
    const chartSerie = (chartMotionReference as any).chart.series[0]

    chartSerie.update({ data: this.suspectedMotionEvents.reverse(), name: 'Motion' })
    this.suspectedMotionRanges.forEach((eventRange: IMotionEventRange) => {
      chart.xAxis[0].addPlotBand(eventRange)
    })
  }

  mounted() {
    this.setData()
  }
}
