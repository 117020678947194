var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasMotionHistory
        ? _c("Chart", {
            ref: "chartMotionReference",
            attrs: { options: _vm.chartOptions }
          })
        : _c("p", [_vm._v(_vm._s(_vm.$t("Default.NoValue")))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }